body {
    color: darkslategray;
}

.popup-modal-content {
    width: 300px !important;
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
}

.popup-title {
    height: 40px;
    position: absolute;
    width: 100%;
    left: 0px;
    background-color: whitesmoke;
    top: 0px;
    text-align: center;
    padding-top: 5px;
    color: black;
}

.popup-content {
    margin-top: 40px;
    padding-top: 10px;
    width: 100%;
    text-align: center;
}

.popup-modal {
    height: 70px;
}

.left-margin {
    margin-left: 10px;
}

.toast-wrapper {
    position: fixed;
    top: 80px;
    right: 30px;
    z-index: 100 !important;
}

.custom-tooltip {
    max-width: 300px !important;
    word-wrap: break-word;
    white-space: normal;
}


/*PARA EL MENU DE LA APLICACION */
#navMenu {
    direction: rtl;
    height: 100vh;
    overflow-x: auto;
}

/*#navMenu::-webkit-scrollbar {
    width: 4px;
    height: 2px;
}

#navMenu::-webkit-scrollbar-track {
    background: transparent;
    padding: 2px;
}

#navMenu::-webkit-scrollbar-thumb {
    background-color: #6b6b6b;
}*/

#navMenuItem {
    direction: ltr;
}

#collapseMenu {
    height: 100vh;
}

/*PARA EL MENU DE LA APLICACION*/


.toast {
    opacity: 0.95 !important;
    width: 250px;
}

.toast-header {
    color: dodgerblue !important;
}

.card-bottom-list {
    max-height: 303px;
}

.bg-gradient-primary-green {
    background-image: linear-gradient(180deg, #0e1e24 10%, #00c6b6 100%);
    background-size: cover;
}

.bg-gradient-primary-green-2 {
    /*background-image: linear-gradient(180deg,#032b0c 10%, #0cad34 100%);*/
    /*background-color: #032b0c;*/
    background-size: cover;
}


.icon-green {
    color: cadetblue;
}

.bg-custom-green {
    background-color: #f0fff3;
}

.bg-custom-dark {
    /*background-color: #032b0c;*/
}

.dark-breadcrumb {
    /*background-color: #032b0c !important;*/
    margin-top: 15px !important;
}

li a {
    color: cadetblue;
}

.cadet {
    color: cadetblue;
}

.green-email {
    color: #8eeda2;
}

.text-green {
    color: cadetblue !important;
    font-family: sans-serif !important;
}

.toggle-area {
    display: none;
}

.collapsed {
    display: block;
}

.toggle-button {
    background-color: #0e1e24 !important;
    border-color: #0e1e24 !important;
    width: 40px;
    color: seagreen !important;
}


@media (max-width: 380px) {
    .collapsed {
        display: none !important;
    }

    .toggle-area {
        position: fixed;
        bottom: 2%;
        left: 2%;
        z-index: 200 !important;
        display: block;
    }
}

.white-breadcrumb {
    background-color: white !important;
    margin-top: 15px !important;
}

.portlet {
    max-height: 350px;
}

.portlet-100 {
    max-height: 100px;
}

.portlet-200 {
    max-height: 200px;
}

.portlet-300 {
    max-height: 300px;
}

.portlet-400 {
    max-height: 400px;
}

.portlet-500 {
    max-height: 500px;
}

.portlet-600 {
    max-height: 600px;
}

.portlet-700 {
    max-height: 700px;
}

.portlet-800 {
    max-height: 800px;
}

.portlet-900 {
    max-height: 900px;
}

.portlet-1000 {
    max-height: 1000px;
}

.portlet-1100 {
    max-height: 1100px;
}

.portlet-1200 {
    max-height: 1200px;
}

.text-form {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #6e707e;
    background-color: #fff;
    background-clip: padding-box;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.header-buttons {
    display: flex;
    position: absolute;
    right: 10px;
    top: 8px;
}

.header-buttons-bottom-right {
    position: absolute;
    right: 10px;
    bottom: 10px;
}

.header {
    position: absolute;
    top: 8px;
}

.right {
    display: flex;
    justify-content: flex-end;
}

.btn-green {
    background-color: cadetblue !important;
    border-color: cadetblue !important;
    margin-right: 3px;
    width: 40px;
}

.btn-blue {
    background-color: lightblue !important;
    border-color: lightblue !important;
    margin-right: 3px;
    width: 40px;
}

.btn-red {
    background-color: indianred !important;
    border-color: indianred !important;
    margin-right: 3px;
    width: 40px;
}

.btn-redsmall {
    color: indianred !important;
}

.table-row {
    cursor: pointer;
    color: black;
}

.selected {
    background-color: lavender;
    color: black;
}


.react-autosuggest__container {
    position: relative;
    flex: 1;
}

.react-autosuggest__input {
    width: 100%;
    height: 100%;
    font-size: 1rem;
    font-weight: 400;
    border: 0px solid #fff;
    margin-left: -20px;
}

.react-autosuggest__input--focused {
    outline: none;
}

.react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
    display: none;
}

.react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    width: 100%;
    border: 1px solid #aaa;
    background-color: #fff;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
}

.react-autosuggest__suggestions-container--open-initial {
    display: block;
    position: initial;
    width: 100%;
    border: 1px solid #aaa;
    background-color: #fff;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
}

.react-autosuggest__suggestions-container--open-200 {
    display: block;
    position: absolute;
    width: 200%;
    border: 1px solid #aaa;
    background-color: #fff;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
}

.react-autosuggest__suggestions-container--open-100 {
    display: block;
    position: absolute;
    border: 1px solid #aaa;
    background-color: #fff;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
}

.react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    max-height: 300px;
    overflow-y: auto;
}

.react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
}

.react-autosuggest__suggestion-match {
    color: red;
    font-weight: bold;
}

.react-autosuggest__suggestion--focused .highlight {
    color: #120000;
}

.highlight {
    color: #ee0000;
    font-weight: bold;
}

.center-all {
    display: flex;
    justify-content: center;
}


/*para dejar el header FIJO*/
/*para dejar el header FIJO*/
table {
    text-align: left;
    position: relative;
    border-collapse: collapse;
    font-size: 0.9rem;
}

th, td {
    padding: 0.25rem;
}

tr.red th {
    background: red;
    color: white;
}

th {
    background: white;
    position: sticky;
    top: 0px;
    /*z-index: 1020;*/
    z-index: auto;
    box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
}

/*para dejar el header FIJO*/
/*para dejar el header FIJO*/


/*REACT DATE PICKER*/
.customDatePickerWidth,
.customDatePickerWidth > div.react-datepicker-wrapper,
.customDatePickerWidth > div > div.react-datepicker__input-container
.customDatePickerWidth > div > div.react-datepicker__input-container input {
    width: 100%;
}

.react-datepicker-button-clear {
    cursor: pointer;
    background-color: transparent;
    border: 0;
    outline: 0;
    position: absolute;
    top: 1px;
    right: 1px;
    height: 100%;
    display: table-cell;
    vertical-align: middle;
}

/*REACT DATE PICKER*/

.custom-file-input {
    content: "Elegir";
}

.custom-file-label::after {
    content: "Elegir";
}

#hiddenBorderBottomTD {
    border: 0px solid black !important;
}

/* place holder rojo */
input.red-placeholder::placeholder {
    color: red !important;
}
input.red-placeholder::-webkit-input-placeholder { /* Para Google Chrome */
    color: red !important;
}
input.red-placeholder:-ms-input-placeholder { /* Para Internet Explorer 10-11 */
    color: red !important;
}
input.red-placeholder::-ms-input-placeholder { /* Para Microsoft Edge */
    color: red !important;
}




/**/
#image_branding {
    max-width: 220px;
    min-width: 220px;
    border: thin dashed #b9b9b9;
    border-radius: 5px;
    display: block;
    height: 90px;
    /*margin: 0 auto;*/
    padding: 5px;
}