.block {
  display: block;
}

.flex {
  display: flex;
}

.w-auto {
  width: auto !important;
}

.w-100 {
  width: 100% !important;
}
.w-5 {
    width: 5%;
}
.w-10 {
    width: 10%;
}
.w-15 {
    width: 15%;
}

.w-20 {
    width: 20%;
}

.w-25 {
    width: 25%;
}

.w-30 {
    width: 30%;
}
.w-33 {
    width: 33%;
}
.w-34 {
    width: 34%;
}

.w-35 {
    width: 35%;
}

.w-40 {
    width: 40%;
}

.w-45 {
    width: 45%;
}

.w-50 {
  width: 50%;
}

.w-55 {
  width: 55%;
}

.w-45 {
  width: 45%;
}

.w-60 {
  width: 60%;
}

.w-40 {
  width: 40%;
}

.w-48 {
  width: 48%;
}

.w-17 {
  width: 17%;
}

.w-18 {
  width: 18%;
}

.w-20 {
    width: 20%;
}

.w-22 {
    width: 22%;
}

.w-22 {
    width: 22%;
}


.row__invoice {
    position: relative;
    border-bottom: 1px solid $color-gray;
}




.table__border_bottom {
    position: relative;
    border-top: 2px solid $color-gray;
    border-right: 2px solid $color-gray;
    border-left: 2px solid $color-gray;
}

.table__border {
    position: relative;
    border-right: 2px solid $color-gray;
    border-left: 2px solid $color-gray;
}

.row__border_left {
    border-bottom: 2px solid $color-gray;
    border-right: 2px solid $color-gray;
}

.row__border_right {
    border-bottom: 2px solid $color-gray;
    }

.row__border_top {
    border-top: 2px solid $color-gray;
}

.row__border_onlyRight {
    border-right: 2px solid $color-gray;
}




.row__remove {
    display: flex;
    padding: 0;
    position: absolute;
    top: 10px;
    right: -20px;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;

    .row_invoice:hover &,
    .row_invoice:focus-within & {
        opacity: 1;
    }
}
