#register-page-container {
    display: flex;
    width: 100%;
}

#register-side-bar {
    position: fixed; /* Fija la barra lateral en su posición */
    top: 0;
    left: 0;
    height: 100vh; /* Asegura que la barra lateral tenga altura completa */
    overflow-y: auto;
    width: 30%;
    background-color: #e6f0ff; /* Azul flojito */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    text-align: center; /* Asegura que todo el texto esté centrado */
    font-family: 'Arial', sans-serif; /* Fuente más legible */
}

    #register-side-bar img {
        width: 150px; /* Ajusta según necesites */
        margin-bottom: 100px; /* Espacio después del logo */
    }

    #register-side-bar p {
        margin-bottom: 20px; /* Espaciado entre párrafos */
        color: #333; /* Color de texto oscuro para mejor legibilidad */
        font-size: 16px; /* Tamaño de letra adecuado */
        line-height: 1.5; /* Espaciado de línea para mejor lectura */
    }

#register-form-container {
    min-height: 100vh;
    margin-left: 30%; /* Desplaza el contenedor a la derecha para no solapar la barra lateral fija */
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: flex-start; /* Cambio de 'center' a 'flex-start' */
    background-color: white;
    padding-top: 20px; /* Añade espacio en la parte superior para asegurarse de que el contenido no esté oculto */
    box-sizing: border-box; /* Asegura que el padding no añada al alto total */
}

#register-title {
    text-align: center;
    color: #333;
}

#register-input {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
    border: 1px solid #ccc;
    display: block;
}

.register-cards-container-horizontal {
    display: flex;
    justify-content: center; /* Centra los elementos horizontalmente en el contenedor */
    align-items: center; /* Alineación vertical, si es necesario */
    margin-bottom: 100px;
}

.register-card-header {
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.register-card {
    flex: 1 1 200px; /* Permite que las tarjetas crezcan para llenar el espacio */
    margin-right: 25px;
    margin-left: 25px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 8px 16px rgba(0,0,0,0.20);
    transition: transform 0.3s ease-in-out;
    cursor: pointer;
    border: 1px solid #ccc;
    padding: 0px 0px 20px 0px;
    text-align: center;
    cursor: pointer;
    transition: transform 0.3s ease;
    min-width: 200px; /* Mínimo ancho de cada tarjeta */
    max-width: calc(25% - 20px); /* Máximo ancho menos el margen para 4 tarjetas */
}

    .register-card.selected {
        border: 1px solid blue;
        background-color: #e6f0ff;
    }

    .register-card:hover {
        /*transform: translateY(-5px);*/ /* Efecto de elevación al pasar el ratón */
        transform: scale(1.05);
    }

    .register-card h3 {
        font-size: 1.4em;
        color: #333;
        padding: 24px 32px;
        margin: 0;
        background-color: #ececec; /* Color por defecto, cambiará dinámicamente */
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }

    .register-card ul {
        list-style: none;
        padding: 0 16px 16px 16px;
        margin: 0;
    }

    .register-card li {
        padding: 4px 0;
        border-bottom: 1px solid #eee;
        font-size: 0.8em;
    }

        .register-card li:last-child {
            border-bottom: none;
        }

#firstMetadataKey {
    color: #555;
    font-size: 1.2em;
    font-weight: bold;
    padding: 8px 16px;
    margin-bottom: -10px;
}

#secondMetadataKey {
    color: #666;
    font-size: 0.7em;
}

.register-row {
    display: flex;
    width: 100%; /* Asegura que el contenedor de la fila use todo el ancho disponible */
    margin: 10px 0; /* Añade margen alrededor de la fila completa */
}

.passwordRequirements {
    display: flex;
    flex-direction: column; /* Asegura que los elementos se alineen en columna */
    justify-content: center; /* Centra los elementos horizontalmente */
    align-items: center; /* Centra los elementos verticalmente */
}

.register-custom-input-container {
    flex: 1; /* Permite que cada input crezca para llenar el espacio */
    margin: 0 10px; /* Añade un pequeño margen a los lados de cada input para separación */
    display: flex; /* Permite un mejor control del label y el input */
    flex-direction: column; /* Alinea el label sobre el input */
    align-items: center; /* Centra los elementos horizontalmente en el contenedor */
    /*justify-content: center;*/ /* Centra los elementos verticalmente en el contenedor */
}

    .register-custom-input-container:first-child {
        margin-left: 0;
    }

    .register-custom-input-container:last-child {
        margin-right: 0;
    }

.register-header-text {
    padding-right: 20px;
    padding-left: 20px;
    text-align: center;
    font-size: 2em; /* Tamaño grande para el título */
    color: #333; /* Color oscuro para contraste */
    margin-top: 20px; /* Espaciado adecuado alrededor del texto */
    font-weight: bold;
    /*margin-bottom: -70px;*/ /* Espacio antes del contenedor de tarjetas */
}

.register-subheader-text {
    padding-right: 20px;
    padding-left: 20px;
    text-align: center;
    font-size: 1.2em; /* Tamaño grande para el título */
    color: #333; /* Color oscuro para contraste */
    margin-top: 30px; /* Espaciado adecuado alrededor del texto */
    margin-bottom: -40px; /* Espacio antes del contenedor de tarjetas */
}

#register-data-section {
    width: 100%; /* Asegura que ocupe todo el ancho disponible */
    border: 2px solid #007bff;
    border-radius: 10px;
    padding: 20px;
    margin-top: 20px;
    background-color: #f9fcff;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    display: flex; /* Utiliza flexbox para organizar los elementos */
    flex-direction: column; /* Asegura que los elementos se alineen en columna */
    justify-content: center; /* Centra los elementos horizontalmente */
    align-items: center; /* Centra los elementos verticalmente */
}

.register-contact-sales {
    background-color: #2a2a2a; /* Color de fondo oscuro */
    color: #ffffff; /* Texto blanco */
    padding: 10px 20px; /* Espaciado alrededor del texto */
    font-family: Arial, sans-serif; /* Tipo de fuente */
    font-size: 14px; /* Tamaño del texto */
    justify-content: center;
    text-align: center
}

    .register-contact-sales p {
        margin: 0; /* Elimina márgenes por defecto de los párrafos */
        line-height: 1.5; /* Espaciado entre líneas */
        font-size: 0.8em;
    }

.register-phone-icon {
    margin-right: 5px; /* Espacio entre el ícono y el número */
    color: white
}



.register-support-text {
    text-align: center;
    font-size: 1.2em;
    margin-top: 30px;
    font-weight: bold;
}

.register-cards-container {
    display: flex;
    justify-content: center; /* Centra los elementos horizontalmente en el contenedor */
    align-items: center; /* Alineación vertical, si es necesario */
    width: 100%; /* Opcional, dependiendo del diseño total */
    padding: 20px 0; /* Añade padding vertical si es necesario */
}

.register-card-horizontal {
    cursor: pointer;
    display: flex;
    align-items: center;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0,0,0,0.1);
    padding: 10px 20px;
    width: 400px;
    height: 100px;
    transition: transform 0.3s ease;
}

    .register-card-horizontal:hover {
        /*transform: translateY(-5px);*/ /* Efecto de elevación al pasar el ratón */
        transform: scale(1.05);
    }

    .register-card-horizontal.selected {
        border: 1px solid blue;
        background-color: #e6f0ff;
    }

.register-card-icon i {
    font-size: 24px; /* Tamaño del icono, ajustable */
    color: #007bff; /* Color del icono, puedes ajustarlo a tus necesidades */
    margin-right: 20px; /* Incrementa el margen derecho para más espacio */
    margin-left: 10px; /* Incrementa el margen derecho para más espacio */
}

.register-card-icon img {
    width: 40px; /* Ajustar el tamaño según necesites */
    height: 40px;
    margin-right: 20px; /* Espacio entre el icono y el texto */
}

.register-card-info h3 {
    margin: 0;
    color: #333; /* Color del texto */
    font-size: 16px; /* Tamaño del texto */
}

.register-card-info p {
    margin: 0;
    color: #666; /* Color del texto para el precio */
    font-size: 14px; /* Tamaño del texto para el precio */
}

.register-optional-left-card {
    border-radius: 20px;
    margin-right: 15px;
}

.register-optional-right-card {
    border-radius: 20px;
}

.register-support-custom-tooltip {
    max-width: 300px; /* Establece el ancho máximo */
}

.register-possibilitiesText {
    text-align: center;
    font-size: 0.8em; /* Tamaño grande para el título */
    color: #333; /* Color oscuro para contraste */
    margin-top: -40px; /* Espaciado adecuado alrededor del texto */
    margin-bottom: 20px; /* Espacio antes del contenedor de tarjetas */
    padding-right: 20px;
    padding-left: 20px;
}

.register-password-requirements {
    display: flex; /* Utiliza flexbox para organizar los elementos */
    flex-wrap: wrap; /* Permite que los elementos se envuelvan a la siguiente línea si es necesario */
    gap: 10px; /* Espacio entre los elementos */
    padding: 0;
    margin-top: 10px; /* Espacio superior para separar del campo de entrada */
    list-style-type: none; /* Elimina los puntos de la lista */

    flex-direction: column;
    /*justify-content: center;*/ /* Centra los elementos horizontalmente dentro de la lista */
}

    .register-password-requirements li {
        display: flex;
        align-items: center;
        margin-bottom: 0; /* Elimina el margen inferior */
        border: 1px solid #ddd; /* Añade un borde alrededor de cada elemento */
        border-radius: 5px; /* Bordes redondeados */
        padding: 5px 10px; /* Espaciado interno */
        background-color: #f9f9f9; /* Fondo claro */
        font-size: 0.8em; /* Ajusta el tamaño de la fuente */
    }

        .register-password-requirements li svg {
            margin-right: 5px; /* Espacio entre el icono y el texto */
        }

.register-pricing-summary-container {
    display: flex;
    justify-content: center;
    margin-bottom: 80px;
}

.register-pricing-summary {
    width: 400px; /* Asegúrate de que el ancho esté definido */
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
}

.register-header {
    background-color: #e0f7e0;
    text-align: center;
    padding: 8px;
    border-radius: 8px;
    margin-bottom: 16px;
}

.register-discount-badge {
    color: #007700;
    font-weight: bold;
}

.register-billing-period {
    margin-bottom: 16px;
}

.register-options {
    display: flex;
    justify-content: space-between;
}

.register-plan-details {
    margin-bottom: 16px;
}

.register-plan {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px
}

.register-total {
    margin-top: 10px;
    margin-bottom: 16px;
    font-weight: bold;
}

.register-discount-info, .register-total-price {
    display: flex;
    justify-content: space-between;
}

.register-continue-button {
    width: 100%;
    padding: 12px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
}

    .register-continue-button:hover {
        background-color: #0056b3;
    }

.register-footer {
    font-size: 12px;
    color: #555;
    text-align: center;
}

    .register-footer a {
        color: #007bff;
        text-decoration: none;
    }

        .register-footer a:hover {
            text-decoration: underline;
        }

.register-logo {
    display: none;
}

.register-button {
    width: 400px;
}


.login-container {
    flex: 0.40;
    align-self: center;
    max-width: 400px;
}

.login-title {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 16px;
    color: #6c757d;
}

.login-subtitle {
    font-size: 0.9rem;
    margin-bottom: 16px;
    color: #6c757d;
}

.changePlanText {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #ffcccb;
    color: #d8000c;
    padding: 10px;
    border: 1px solid #d8000c;
    border-radius: 5px;
    margin-top: -45px;
    margin-bottom: 60px;
    font-size: 14px;
    font-weight: bold;
    width: 400px;
    margin-left: auto;
    margin-right: auto;
}




@media (max-width: 1200px) {
    .register-card {
        width: 400px;
        max-width: 100%;
    }

    .register-cards-container {
        flex-direction: column; /* Cambia la dirección del flexbox a vertical */
    }

    .register-cards-container-horizontal {
        flex-direction: column; /* Cambia la dirección del flexbox a vertical */
    }

    .register-card-horizontal {
        width: 400px; /* Hace que cada card use el 100% del ancho disponible */
        margin: 5px 0; /* Ajusta el margen para apilamiento vertical */
    }

    .login-container {
        flex: 0.60;
        align-self: center;
        max-width: 300px;
    }
}



@media (max-width: 768px) {
    .register-row {
        flex-direction: column; /* Cambia la dirección del flex para que los elementos se apilen verticalmente */
        align-items: stretch; /* Alinea los elementos para que se estiren y ocupen todo el ancho */
    }

    .register-custom-input-container {
        width: 100%; /* Hace que cada contenedor de input use todo el ancho disponible */
        margin: 10px 0; /* Ajusta los márgenes para una mejor visualización en vertical */
    }

    #register-input {
        width: calc(100% - 20px); /* Ajusta el ancho del input para considerar el padding */
        margin: 10px auto; /* Centra el input y añade margen para separación */
    }

    #register-button {
        margin-top: 20px; /* Añade espacio antes del botón para mejor separación */
    }

    #register-side-bar {
        display: none;
    }

    #register-form-container {
        margin-left: 0; /* Elimina el margen porque la barra lateral ya no es fija */
        width: 100%; /* Usa el ancho completo de la ventana */
    }

    #register-page-container {
        flex-direction: column; /* Cambia de fila a columna */
    }

    .register-card {
        width: 300px;
        max-width: 100%;
    }

    .register-card-horizontal {
        width: 300px; /* Hace que cada card use el 100% del ancho disponible */
        margin: 5px 0; /* Ajusta el margen para apilamiento vertical */
    }

    .register-pricing-summary {
        width: 300px; /* Asegúrate de que el ancho esté definido */
    }

    .register-logo {
        display: block;
        width: 150px;
        margin: 0 auto;
    }

    .register-button {
        width: 300px;
    }

    .login-container {
        flex: 1;
        align-self: center;
        max-width: 300px;
    }

    .changePlanText {
        width: 300px;
    }
}
