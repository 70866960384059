.right {
  text-align: right;
}

.center {
  text-align: center;
}

.bold {
  font-weight: 600;
}

.bold2 {
    font-weight: 900;
}

.fs-4 {
    font-size: 4px;
}
.fs-6 {
    font-size: 6px;
}
.fs-8 {
    font-size: 8px;
}
.fs-10 {
    font-size: 10px;
}
.fs-12 {
    font-size: 12px;
}
.fs-14 {
    font-size: 14px;
}
.fs-16 {
    font-size: 16px;
}
.fs-18 {
    font-size: 18px;
}
.fs-20 {
  font-size: 20px;
}

.fs-30 {
  font-size: 30px;
}

.fs-45 {
  font-size: 45px;
}

.upper {
  text-transform: uppercase;
}
